import React from 'react';
import { InfoCard, Link } from '@backstage/core-components';
import {
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

export const ImportantLinksCard = () => {
  return (
    <InfoCard
      title="Important Content"
      variant="gridItem"
    >
      <List dense>
        <ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Link to="https://loggidev.atlassian.net/l/cp/Hsmh9YwC">
                  The Loggi Engineering Manifesto
                </Link>
              }
              secondary="General guidelines for our tech and engineering team."
            />

          </ListItem>
        </ListItem>
        <ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Link to="https://loggidev.atlassian.net/l/cp/bzaK3A8z">
                  Dev Onboarding
                </Link>
              }
              secondary="Are you a new Logger? Here is how you set up your dev environment."
            />
          </ListItem>
        </ListItem>
        <ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Link to="https://loggitreinamentos.skore.io/pt/spaces/40844#section-185967">
                  Training Material (Skore)
                </Link>
              }
              secondary="Our training platform with a lot of material for our tech loggers."
            />
          </ListItem>
        </ListItem>
        <ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Link to="https://docs.google.com/spreadsheets/d/17Ma8O3Fm15zsxG5PucLLaIwmoSnUl59b79_QhRvudoI/edit?usp=sharing">
                  Loggi-web release manager's schedule
                </Link>
              }
              secondary="Who is going to deploy loggi-web today?"
            />
          </ListItem>
        </ListItem>
        <ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Link to="https://loggidev.atlassian.net/wiki/spaces/DEVOPS/pages/719847674/Deploy+loggi-web">
                  Loggi-web deploy instructions
                </Link>
              }
              secondary="Are you the release manager today? Here are the instructions to deploy loggi-web."
            />
          </ListItem>
        </ListItem>
        <ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Link to="https://loggidev.atlassian.net/l/cp/EiwpyjTk">
                  How to create or add new projects to SonarCloud
                </Link>
              }
              secondary="Instructions on how to add your project to SonarCloud."
            />
          </ListItem>
        </ListItem>
      </List>
    </InfoCard>
  );
};

