/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 80,
  }
});

const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 536 261"
    >
      <g id="Group_7" data-name="Group 7">

        <path d="M235.5,90.11H222.17v70.41h42.38V147.6H235.5Z" fill='#fff'/>

        <path d="M292.74,108.41c-15.3,0-27,11.4-27,26.41s11.65,26.47,27,26.46,27-11.35,27-26.41-11.48-26.4-26.94-26.46m-.13,41c-8.19,0-14-6.19-13.91-14.71s5.85-14.35,14.05-14.34,14,6,13.93,14.58-5.84,14.53-14.08,14.47" fill='#fff'/>

        <path d="M363.15,112a28.26,28.26,0,0,0-13.92-3.6c-15.3-.06-27,11.4-27,26.4s11.66,26.48,27,26.47a28.1,28.1,0,0,0,14-3.63v5.71c0,8.57-5.84,14.52-14.08,14.52-10.58,0-15.24-10.26-15.24-10.26l-9.69,5.89s5.57,16.27,25,16.27c15.4,0,27-11.35,27-26.42v-55h-13V112m0,23.06c-.11,8.48-5.88,14.38-14.07,14.32s-14-6.2-13.9-14.71,5.84-14.35,14.05-14.35,13.88,5.9,13.92,14.4v.33" fill='#fff'/>

        <path d="M419.64,112a28.24,28.24,0,0,0-13.92-3.6c-15.3-.06-27,11.4-27,26.4s11.65,26.48,27,26.47a28.13,28.13,0,0,0,14-3.63v5.71c0,8.57-5.85,14.52-14.09,14.52-10.58,0-15.23-10.26-15.23-10.26l-9.7,5.89s5.57,16.26,25,16.26c15.4,0,27-11.34,27-26.41v-55h-13V112m0,23.06c-.11,8.48-5.88,14.38-14.07,14.32s-14-6.2-13.9-14.71,5.84-14.36,14-14.35,13.88,5.9,13.93,14.4v.34" fill='#fff'/>
        <rect x="437.91" y="108.37" width="13.33" height="52.15" fill='#fff'/>

        <path d="M444.58,103.34a6.67,6.67,0,1,0-6.67-6.67,6.67,6.67,0,0,0,6.67,6.67h0" fill='#fff'/>

        <path d="M195.1,100.06a77,77,0,0,0-7.79-3.43l-.74-.54s-17.65-12.62-25.43-15.9-21.31-10.4-35-10a83.09,83.09,0,0,0-19.89,2.88c-2.77.67-4.63,1.45-1.59,2.88,1,.48,5.36,3.06,10.62,6.07,7.7,4.39,17.37,9.69,21.58,10.65a76.47,76.47,0,0,0,19.12,2.09c9.67-.2,14.37,3.92,15.32,5.43-1.73-2.46-8.31-4.43-17.1-3.89-8.46.52-15.26,0-19.21-1.22a172.83,172.83,0,0,1-23.06-9,55,55,0,0,0-5.73,2.06c-2.43,1-4.72,2.89-1.3,3.86,2.45.69,25.32,8.87,32,9.26a69.47,69.47,0,0,0,17.63-1c8.65-1.63,13.16,1.92,14.21,3.3a21.86,21.86,0,0,0-4,14.18c-31.62-5.58-41.71-8.46-48.85-9.69-12.49-2.15-28.34,8.43-30.29,21.52-1.1,7.45.8,14.4,5.32,20.83,5,7.12,11.59,13.26,12.55,14.56.61,1.12.41,4.06.45,6,0,2.23,2.84,4.22,4.47,4.37,1,.09,19.69,5.94,19.69,5.94l27.73,8.53s.43-1.91.51-2.72c.21-2.05-1.35-7.12-7.85-9-9.56-2.88-11.45-3.42-13.48-4-1.1-.33-.33-.35,1.37-1.69a27.37,27.37,0,0,0,6.65-8.47c5.66-12-1.46-22.76-1.46-22.76a28.15,28.15,0,0,1,6.11,12.84c2.09,10.2-4.82,18.29-4.82,18.29l4.12,1.2s26.65-26.84,29.25-29.74c2.2-2.45,6-4,8-4.62,7.1-2.06,16-6,18.25-7.48s5-6.48,5.73-7.73a12.45,12.45,0,0,0,1.78-4.75c.06-.65-3.74-5.72-3.74-5.72s-6.07-10.28-11.08-13.32" fill='#fff'/>

        <path d="M117.54,182.76a2.69,2.69,0,0,0-1,3.66,2.72,2.72,0,0,0,1.19,1.11c2,1.07,8.88-2.68,8.88-2.68l-6.86-2.16a2.91,2.91,0,0,0-2.19.07" fill='#fff'/>
      </g>
    </svg>
  );
};

export default LogoFull;