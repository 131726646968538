/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '32px',
  },
  path: {
    fill: '#fff',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 187 187"
    >
      <path
        className={classes.path}
        d="M140.64,64.06a75.09,75.09,0,0,0-7.74-3.41l-.74-.54s-17.55-12.54-25.29-15.79S85.67,34,72,34.37a81.75,81.75,0,0,0-19.78,2.86c-2.75.65-4.6,1.44-1.57,2.86,1,.47,5.32,3,10.56,6,7.65,4.36,17.28,9.63,21.46,10.58a76.22,76.22,0,0,0,19,2.08c9.62-.2,14.29,3.89,15.24,5.39-1.72-2.44-8.28-4.4-17-3.86-8.41.51-15.17,0-19.11-1.21a172.91,172.91,0,0,1-22.94-9,53.56,53.56,0,0,0-5.69,2.06c-2.43,1-4.69,2.86-1.3,3.82,2.44.69,25.18,8.82,31.81,9.22a70.16,70.16,0,0,0,17.54-1c8.6-1.61,13.08,1.91,14.14,3.28a21.64,21.64,0,0,0-4,14.08C79,76,68.93,73.16,61.82,71.94,49.41,69.81,33.64,80.31,31.7,93.32c-1.1,7.39.79,14.3,5.29,20.68,5,7.07,11.52,13.18,12.48,14.47.61,1.1.41,4,.46,6,0,2.21,2.81,4.19,4.44,4.33,1,.1,19.58,5.9,19.58,5.9l27.6,8.48s.42-1.9.5-2.7c.21-2-1.34-7.06-7.81-8.94l-13.41-4c-1.09-.32-.33-.34,1.37-1.68a26.93,26.93,0,0,0,6.61-8.41c5.63-12-1.46-22.59-1.46-22.59a27.84,27.84,0,0,1,6.09,12.74c2.07,10.13-4.8,18.16-4.8,18.16l4.1,1.2s26.51-26.67,29.1-29.55c2.19-2.42,6-4,7.92-4.59a99.63,99.63,0,0,0,18.16-7.42c2.24-1.42,5-6.44,5.69-7.68A12.15,12.15,0,0,0,155.39,83c.06-.65-3.72-5.68-3.72-5.68S145.63,67.08,140.64,64.06Z"
      />
    </svg>
  );
};

export default LogoIcon;
