import React from 'react';
import AWSIcon from './aws.png';
import GithubIcon from './github.png';
import GrafanaIcon from './grafana.png';
import KibanaIcon from './kibana.png';
import OpenSearchIcon from './opensearch.png';
import LoggiIcon from './loggi.png';
import OpsGenieIcon from './opsgenie.png';
import SentryIcon from './sentry.png';
import UnleashIcon from './unleash.png';
import VaultIcon from './vault.png';
import SigNozIcon from './signoz.svg';
import AirflowIcon from './airflow.png';
import KialiIcon from './kiali.png';
import RundeckIcon from './rundeck.png';
import SonarcloudIcon from './sonarcloud.png';
import DockerhubIcon from './dockerhub.png';
import ArgoCDIcon from './argocd.png';
import OpenMetadataIcon from './openmetadata.png';

export const HomePageLoggiLogo = () => {
  return <img src={LoggiIcon} height='200px' />;
};

export const SentryLogo = () => {
  return <img src={SentryIcon} width='80%' />;
};

export const GrafanaLogo = () => {
  return <img src={GrafanaIcon} width='80%' />;
};

export const GithubLogo = () => {
  return <img src={GithubIcon} width='80%' />;
};

export const UnleashLogo = () => {
  return <img src={UnleashIcon} width='80%' />;
};

export const KibanaLogo = () => {
  return <img src={KibanaIcon} width='50%' />;
};

export const OpenSearchLogo = () => {
  return <img src={OpenSearchIcon} width='50%' />;
};

export const AWSLogo = () => {
  return <img src={AWSIcon} width='80%' />;
};

export const OpsGenieLogo = () => {
  return <img src={OpsGenieIcon} width='80%' />;
};

export const VaultLogo = () => {
  return <img src={VaultIcon} width='80%' />;
};

export const SigNozLogo = () => {
  return <img src={SigNozIcon} width='80%' />;
};

export const AirflowLogo = () => {
  return <img src={AirflowIcon} width='80%' />;
};

export const KialiLogo = () => {
  return <img src={KialiIcon} width='80%' />;
};

export const RundeckLogo = () => {
  return <img src={RundeckIcon} width='80%' />;
};

export const SonarcloudLogo = () => {
  return <img src={SonarcloudIcon} width='80%' />;
};

export const DockerhubLogo = () => {
  return <img src={DockerhubIcon} width='80%' />;
};

export const ArgoCDLogo = () => {
  return <img src={ArgoCDIcon} width='80%' />;
};

export const OpenMetadataLogo = () => {
  return <img src={OpenMetadataIcon} width='80%' />;
};
