import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin
} from '@backstage/plugin-catalog-import';
import { orgPlugin } from '@backstage/plugin-org';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import React from 'react';
import { Route } from 'react-router';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage
} from '@backstage/plugin-techdocs';

import {
  RELATION_API_CONSUMED_BY, RELATION_API_PROVIDED_BY, RELATION_CONSUMES_API, RELATION_DEPENDENCY_OF, RELATION_DEPENDS_ON, RELATION_HAS_PART, RELATION_OWNED_BY, RELATION_OWNER_OF, RELATION_PART_OF, RELATION_PROVIDES_API
} from '@backstage/catalog-model';

import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { Root } from './components/Root';
import { searchPage } from './components/search/SearchPage';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';

import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { CatalogGraphPage, Direction } from '@backstage/plugin-catalog-graph';
import { PermissionedRoute } from '@backstage/plugin-permission-react';

import { OpsgeniePage } from '@k-phoen/backstage-plugin-opsgenie';

import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';

import {
  createTheme, darkTheme, genPageTheme,
  lightTheme, shapes
} from '@backstage/theme';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { default as DarkIcon, default as LightIcon } from '@material-ui/icons/WbSunny';

import { AnnouncementsPage } from '@k-phoen/backstage-plugin-announcements';
import { ExplorePage } from '@backstage/plugin-explore';

import type { IdentityApi } from '@backstage/core-plugin-api';
import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';

import { setTokenCookie } from './cookieAuth';
import { googleAuthApiRef } from '@backstage/core-plugin-api';


const LoggiTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#006AFF',
    },
    secondary: {
      main: '#00BAFF',
    },
    error: {
      main: '#CC0014',
    },
    warning: {
      main: '#FFDD00',
    },
    info: {
      main: '#00BAFF',
    },
    success: {
      main: '#008449',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#000000',
    },
    banner: {
      info: '#00BAFF',
      error: '#CC0014',
      text: '#000000',
      link: '#002766',
    },
    errorBackground: '#FFFFFF',
    warningBackground: '#FFFFFF',
    infoBackground: '#FFFFFF',
    navigation: {
      background: '#006AFF',
      indicator: '#FFFFFF',
      navItem: {
        hoverBackground: '#0c4499',
      },
      color: '#FFFFFF',
      selectedColor: '#FFFFFF',
    },
  },
  defaultPageTheme: 'home',
  // fontFamily: 'Comic Sans MS',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#006AFF', '#006AFF'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#006AFF', '#006AFF'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#006AFF', '#006AFF'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#006AFF', '#006AFF'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#006AFF', '#006AFF'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#006AFF', '#006AFF'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#006AFF', '#006AFF'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#006AFF', '#006AFF'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#006AFF', '#006AFF'], shape: shapes.wave }),
  },
});

const LoggiDarkTheme = createTheme({
  palette: {
    ...darkTheme.palette,
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#00BAFF',
    },
    navigation: {
      background: '#333333',
      indicator: '#FFFFFF',
      navItem: {
        hoverBackground: '#CCCCCC',
      },
      color: '#FFFFFF',
      selectedColor: '#FFFFFF',
    },
  },
  defaultPageTheme: 'home',
  // fontFamily: 'Comic Sans MS',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#333333', '#333333'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#333333', '#333333'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#333333', '#333333'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#333333', '#333333'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#333333', '#333333'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#333333', '#333333'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#333333', '#333333'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#333333', '#333333'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#333333', '#333333'], shape: shapes.wave }),
  },
});

const app = createApp({
  apis,
  themes: [{
    id: 'loggi-theme',
    title: 'Loggi',
    variant: 'light',
    icon: <LightIcon />,
    Provider: ({ children }) => (
      <ThemeProvider theme={LoggiTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ),
  },
  {
    id: 'LoggiDarkTheme',
    title: 'Dark Theme',
    variant: 'dark',
    icon: <DarkIcon />,
    Provider: ({ children }) => (
      <ThemeProvider theme={LoggiDarkTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ),
  }],

  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          provider={{
            id: 'google-auth-provider',
            title: 'Google',
            message: 'Sign In using Google',
            apiRef: googleAuthApiRef,
          }}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApi,
            );

            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  <FlatRoutes>
    {/*<Navigate key="/" to="catalog" />*/}
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/explore" element={<ExplorePage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <PermissionedRoute
      path="/catalog-import"
      permission={catalogEntityCreatePermission}
      element={<CatalogImportPage />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    {/*<Route path="/catalog-graph" element={<CatalogGraphPage />} />*/}
    <Route path="/opsgenie" element={<OpsgeniePage />} />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            selectedKinds: ['component', 'domain', 'system', 'api', 'resource'],
            selectedRelations: [
              RELATION_OWNER_OF,
              RELATION_OWNED_BY,
              RELATION_CONSUMES_API,
              RELATION_API_CONSUMED_BY,
              RELATION_PROVIDES_API,
              RELATION_API_PROVIDED_BY,
              RELATION_HAS_PART,
              RELATION_PART_OF,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
            ],
            mergeRelations: false,
            direction: Direction.TOP_BOTTOM
          }}
        />
      }
    />
  </FlatRoutes>
);

const App = () => (
  <AppProvider>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </AppProvider>
);

export default App;
